import React, { useState } from "react";
import PropTypes from "prop-types";

export const DriverProfileContext = React.createContext();

export const DriverProfileProvider = ({ children }) => {
  const [driverId, setDriverId] = useState(null);

  const onDrawerOpen = driverIdParam => {
    setDriverId(driverIdParam);
  };

  const onDrawerClose = () => {
    setDriverId(null);
  };

  return (
    <DriverProfileContext.Provider
      value={{
        driverId,
        onDrawerOpen,
        onDrawerClose
      }}
    >
      {children}
    </DriverProfileContext.Provider>
  );
};

DriverProfileProvider.propTypes = {
  children: PropTypes.node
};
