import React, { createContext, useContext } from "react";
import { useQuery } from "@apollo/client";
import algoliasearch from "algoliasearch";
import PropTypes from "prop-types";

import config from "Config/config";
import { ALGOLIA_SEARCH_TOKEN } from "Queries/Algolia/Algolia";
import { ClientFactoryContext } from "./ClientProvider";

export const AlgoliaContext = createContext({});

export const AlgoliaProvider = ({ children }) => {
  const { currentOrganizationClient } = useContext(ClientFactoryContext);
  const { data, loading } = useQuery(ALGOLIA_SEARCH_TOKEN, {
    client: currentOrganizationClient,
    skip: !currentOrganizationClient
  });

  const token = data?.algoliaSearchToken;
  console.log(token);
  const client = algoliasearch(config.algolia.appId, config.algolia.apiKey);

  return (
    <AlgoliaContext.Provider value={{ client, loading }}>
      {children}
    </AlgoliaContext.Provider>
  );
};

AlgoliaProvider.propTypes = {
  children: PropTypes.node
};
