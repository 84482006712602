import React from "react";
import { ApolloClient, createHttpLink, from, split } from "@apollo/client/core";
import { ApolloProvider as ReactApolloProvider } from "@apollo/client";
import { ApolloProvider as HooksProvider } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { InMemoryCache } from "@apollo/client/cache";
import { persistCache } from "apollo3-cache-persist";
import { createUploadLink } from "apollo-upload-client";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { extractFiles } from "extract-files";
import objectPath from "object-path";
import PropTypes from "prop-types";
import { sha256 } from "crypto-hash";

import config from "Config/config";
import { authController } from "Login/AuthController";
import { ClientProvider } from "Components/Utils/ClientProvider";
import { getSessionData } from "Analytics/utils/getSessionData";

export const ClientFactoryContext = React.createContext();

//fetcher is a necessary fix for LogRocket working with the Apollo Client.
const fetcher = (...args) => window.fetch(...args);
const cache = new InMemoryCache({
  dataIdFromObject: object =>
    object.id && object.__typename ? object.id + object.__typename : null
});

export const apiV1BatchHttpLink = new BatchHttpLink({
  uri: config.graphqlUrl,
  fetch: fetcher
});

export const apiV1HttpLink = createHttpLink({
  uri: config.graphqlUrl,
  fetch: fetcher
});

export const apiV2HttpLink = createHttpLink({
  uri: config.graphqlUrlv2,
  fetch: fetcher
});

export const apiV2BatchHttpLink = new BatchHttpLink({
  uri: config.graphqlUrlv2,
  fetch: fetcher
});

export const additionalHeadersLink = setContext(async (_, { headers = {} }) => {
  const sessionData = await getSessionData();

  return {
    headers: {
      ...headers,
      "apollographql-client-name": config.clientName,
      "apollographql-client-version": "1.4.0",
      "x-google-session-id": sessionData?.ga_session_id,
      ...(sessionData?.affiliateSource && {
        "x-marketing-affiliate-source": sessionData?.affiliateSource
      }),
      ...(sessionData?.affiliateSourceId && {
        "x-marketing-affiliate-source-id": sessionData?.affiliateSourceId
      }),
      ...(sessionData?.affiliateReferrer && {
        "x-marketing-affiliate-referrer": sessionData?.affiliateReferrer
      }),
      ...(sessionData?.affiliateUtm && {
        "x-marketing-affiliate-utm": sessionData?.affiliateUtm
      })
    }
  };
});

/**
 * The authController.subscribe property is a promise that is resolved
 * only when the authController has finished trying to retrieve a token from
 * local storage and through redirects. It is necessary to await this promise
 * so that the client doesn't prematurely run any queries until we figure out whehter or not
 * the user has valid auth_info.
 */
export const authLink = setContext(async (_, { headers = {} }) => {
  try {
    await authController.subscribe;
    if (authController.isIdTokenValid()) {
      const token = authController.getIdToken();
      headers.authorization = `Bearer ${token}`;
    } else {
      await authController.refreshToken();
      if (authController.isIdTokenValid()) {
        const token = authController.getIdToken();
        headers.authorization = `Bearer ${token}`;
      }
    }
  } catch (e) {
    console.error(e);
    authController.clearIdTokenCache();
  }
  return {
    headers: {
      ...headers
    }
  };
});

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }

  if (networkError) {
    console.error(`[Network Error]: ${networkError}`);
  }
});

const apiV1Link = from([
  authLink,
  additionalHeadersLink,
  errorLink,
  split(
    operation => {
      const tree = operation.variables;
      const files = extractFiles(tree);
      const isFileQuery = files.length > 0;
      const treePath = objectPath(tree);
      files.forEach(({ path, file }) => treePath.set(path, file));
      return isFileQuery;
    },
    createUploadLink({ uri: config.graphqlUrl }),
    split(
      operation => operation.getContext().important,
      from([
        createPersistedQueryLink({
          useGETForHashedQueries: true,
          sha256
        }),
        apiV1HttpLink
      ]),
      apiV1BatchHttpLink
    )
  )
]);

const apiV2Link = from([
  authLink,
  additionalHeadersLink,
  errorLink,
  split(
    operation => operation.getContext().important,
    from([
      createPersistedQueryLink({ useGETForHashedQueries: true, sha256 }),
      apiV2HttpLink
    ]),
    apiV2BatchHttpLink
  )
]);

persistCache({
  cache,
  storage: window.localStorage
});

export const client = new ApolloClient({
  link: split(operation => operation.getContext().apiv2, apiV2Link, apiV1Link),
  cache: cache,
  queryDeduplication: true
});

export const currentUserClient = new ApolloClient({
  link: from([
    authLink,
    additionalHeadersLink,
    errorLink,
    split(
      operation => {
        const tree = operation.variables;
        const files = extractFiles(tree);
        const isFileQuery = files.length > 0;
        const treePath = objectPath(tree);
        files.forEach(({ path, file }) => treePath.set(path, file));
        return isFileQuery;
      },
      createUploadLink({ uri: config.graphqlUrl }),
      split(
        operation => operation.getContext().important,
        from([
          createPersistedQueryLink({
            useGETForHashedQueries: true,
            sha256
          }),
          apiV1HttpLink
        ]),
        apiV1BatchHttpLink
      )
    )
  ]),
  cache: new InMemoryCache({
    dataIdFromObject: object =>
      object.id && object.__typename ? object.id + object.__typename : null
  }),
  name: "Web Current User Client"
});

export function resetAPI() {
  window.localStorage.clear();
  client.resetStore();
  currentUserClient.resetStore();
}

export const resetAPIClients = () => {
  client.resetStore();
  currentUserClient.resetStore();
};

export const ApolloProvider = ({ children }) => {
  return (
    <ClientProvider>
      <ReactApolloProvider client={client}>
        <HooksProvider client={client}>{children}</HooksProvider>
      </ReactApolloProvider>
    </ClientProvider>
  );
};

ApolloProvider.propTypes = {
  children: PropTypes.element
};
