import React, { useContext, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { connect } from "react-redux";
import { compose } from "recompose";
import makeStyles from "@mui/styles/makeStyles";
import get from "lodash/get";

import { closeCreateRooftopDialog } from "Redux/UI/ActionCreators";
import { CURRENT_USER_QUERY } from "Queries/User/UserQueries";
import { SendBirdController } from "SendBird/SendBirdController";
import { DashboardController } from "Fleet/Dashboard/DashboardController";
import { ManualContainer } from "Fleet/Flows/Manual/ManualContainer";
import { CheckInFlowContainer } from "Fleet/Flows/CheckInFlow/CheckInFlowContainer";
import { ClaimsController } from "Fleet/Flows/Claims/ClaimsController";
import { CSVController } from "Fleet/Flows/CSV/CSVController";
import { RouteEnum } from "Enums/RouteEnum";
import { Stripe } from "Components/Utils/Stripe";
import { ThemeFunctionsContext } from "Styles/ThemeProvider";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { SelectListingType } from "Fleet/Flows/SelectListingType/SelectListingType";
//TODO this needs to be added back
//import { MissingInfoDialog } from "Components/Dialog/MissingInfoDialog";
import { CarsDomainPermissionPaths } from "Components/Utils/Permissions/PermissionsPaths";
import { RouteWithPermission } from "Components/Routers/RouteWithPermission";
import { RooftopDialog } from "Components/Dialog/RooftopDialog";
import ProfileCompleterContainer from "Components/Dialog/profileCompleter/ProfileCompleterContainer";
import { CircularProgress } from "Components/Loading/CircularProgress";
import { GET_TOKENS } from "Queries/Organization/OrganizationQueries";
import { PayoutConnectProvider } from "Components/Utils/PayoutConnectProvider";
import { AlgoliaContext } from "Components/Utils/AlgoliaProvider";
import { DriverProfileContext } from "Components/Utils/DriverProfileProvider";
import { DriverProfile } from "Components/Drawers/DriverProfileDrawer";
import { useShowProtectionDialog } from "Components/Dialog/hooks/useShowProtectionDialog";
import { ProtectionPlanDialog } from "Components/Dialog/protectionPlanDialog/ProtectionPlanDialog";
import { UnsavedProgressDialogProvider } from "Components/Utils/UnsavedProgressDialogProvider";
import useZendeskChat from "Components/hooks/useZendeskChat";
import { OwnerAlertProvider } from "Components/Utils/OwnerAlertProvider";
import { OverpricedCarsProvider } from "Components/Utils/OverpricedCarsProvider";
import { OverpricedCarEventTrackingProvider } from "Components/Utils/OverpricedCarEventTrackingProvider";

const useStyles = makeStyles(theme => ({
  loading: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  container: {
    [theme.breakpoints.down("xl")]: {
      marginBottom: theme.spacing(10)
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(11)
    }
  }
}));

export const FleetContainer = compose(
  connect(
    state => ({
      createRooftopDialogToken: get(state, "fleet.ui.createRooftopDialogToken")
    }),
    { closeCreateRooftopDialog }
  )
)(({ closeCreateRooftopDialog, createRooftopDialogToken }) => {
  const classes = useStyles();
  useZendeskChat();

  const { setThemeToFleet } = useContext(ThemeFunctionsContext);
  const { loading: algoliaLoading } = useContext(AlgoliaContext);
  const { driverId } = useContext(DriverProfileContext);
  const {
    handleNewClients,
    setDefaultClients,
    rooftopClients,
    currentRooftopToken,
    organizationClients
  } = useContext(ClientFactoryContext);
  const { data, loading: currentUserLoading } = useQuery(CURRENT_USER_QUERY);

  const { data: returnedTokens, loading: loadingTokens } = useQuery(
    GET_TOKENS,
    {
      context: {
        apiv2: true
      },
      fetchPolicy: "network-only"
    }
  );

  const {
    showDialog: showProtectionDialog,
    loading: showProtectionLoading
  } = useShowProtectionDialog();

  useEffect(() => {
    setThemeToFleet();
  }, [setThemeToFleet]);

  // Create clients for organizations and rooftops
  useEffect(() => {
    const tokenStructure = get(returnedTokens, "getTokens", []);
    if (!loadingTokens) {
      handleNewClients(tokenStructure);
    }
    /*eslint-disable*/
  }, [loadingTokens]);

  useEffect(() => {
    const rooftops = Object.keys(rooftopClients);
    const organizations = Object.keys(organizationClients);
    if (rooftops.length > 0 && organizations.length > 0) {
      setDefaultClients();
    }
  }, [rooftopClients, organizationClients, setDefaultClients]);

  return loadingTokens ||
    !currentRooftopToken ||
    showProtectionLoading ||
    currentUserLoading ||
    algoliaLoading ? (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  ) : (
    <div className={classes.container}>
      <Stripe>
        <UnsavedProgressDialogProvider>
          <OverpricedCarsProvider>
            <OverpricedCarEventTrackingProvider>
              <PayoutConnectProvider>
                {!window.location.pathname.includes("fleet") && (
                  <Redirect to={RouteEnum.baseRoute} />
                )}
                <OwnerAlertProvider>
                  <SendBirdController>
                    {showProtectionDialog && <ProtectionPlanDialog />}
                    <RooftopDialog
                      open={!!createRooftopDialogToken}
                      organizationToken={createRooftopDialogToken}
                      handleClose={closeCreateRooftopDialog}
                    />
                    <ProfileCompleterContainer userProfile={data} />
                    {driverId && <DriverProfile driverId={driverId} />}
                    <Switch>
                      <Route
                        path={RouteEnum.fleet}
                        exact
                        render={() => <Redirect to={RouteEnum.baseRoute} />}
                      />
                      <Route
                        path={RouteEnum.baseRoute}
                        component={DashboardController}
                      />
                      <RouteWithPermission
                        path={RouteEnum.manual}
                        component={ManualContainer}
                        requiredPermissions={[
                          CarsDomainPermissionPaths.listCar
                        ]}
                      />
                      <Route
                        path={`${RouteEnum.checkIn}/:id`}
                        component={CheckInFlowContainer}
                      />
                      <Route
                        path={RouteEnum.claims}
                        component={ClaimsController}
                      />
                      <Route path={RouteEnum.csv} component={CSVController} />
                      <Route
                        path={RouteEnum.selectListingType}
                        component={SelectListingType}
                      />
                    </Switch>
                  </SendBirdController>
                </OwnerAlertProvider>
              </PayoutConnectProvider>
            </OverpricedCarEventTrackingProvider>
          </OverpricedCarsProvider>
        </UnsavedProgressDialogProvider>
      </Stripe>
    </div>
  );
});
