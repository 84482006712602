import axios from "axios";

import config from "Config/config";
import { authController } from "Login/AuthController";
import { getSessionData as getAnalyticsSessionData } from "Analytics/utils/getSessionData";

export const rest = axios.create({
  baseURL: config.restUrl,
  headers: {
    "x-client-name": config.clientName
  }
});

const setAuthHeader = async config => {
  const token = authController.getIdToken();
  config.headers.Authorization = `Bearer ${token}`;
};

const setGoogleSessionId = async config => {
  const sessionData = await getAnalyticsSessionData();
  config.headers["x-google-session-id"] = sessionData.ga_session_id;
};

rest.interceptors.request.use(async config => {
  try {
    await authController.subscribe;
    if (authController.isIdTokenValid()) {
      setAuthHeader(config);
    } else {
      await authController.refreshToken();
      if (authController.isIdTokenValid()) {
        setAuthHeader(config);
      }
    }
  } catch (e) {
    console.error(e);
    authController.clearIdTokenCache();
  }

  setGoogleSessionId(config);

  return config;
});
