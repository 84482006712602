import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSortBy } from "react-instantsearch";
import makeStyles from "@mui/styles/makeStyles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { LocationAutoComplete } from "Components/Forms/Location/LocationAutoComplete";
import { SORT_BY_ENUM } from "./Marketplace";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 1)
    }
  },
  formControl: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(-1)
  },
  select: {
    padding: "0 12px 10px"
  },
  icon: {
    marginRight: theme.spacing(3),
    marginTop: "-4px"
  }
}));

const SortByWidget = ({ items, refine, defaultRefinement, resetPage }) => {
  const classes = useStyles();
  const [sortBy, setSortBy] = useState(defaultRefinement);
  const handleSortByChange = e => {
    refine(e.target.value);
    setSortBy(e.target.value);
    resetPage();
  };

  return (
    <FormControl
      fullWidth
      variant="standard"
      classes={{ root: classes.formControl }}
    >
      <Select
        id="sort-by"
        name="sortBy"
        value={sortBy}
        onChange={handleSortByChange}
        classes={{
          icon: classes.icon,
          select: classes.select
        }}
      >
        {items.map((option, key) => (
          <MenuItem key={key} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
const MarketplaceSortBy = connectSortBy(SortByWidget);

export const MarketplaceFilters = ({
  locationFilter,
  handleLocationUpdate,
  resetPage
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LocationAutoComplete
        input={{
          onChange: e => {
            handleLocationUpdate(e);
            resetPage();
          },
          onBlur: () => {},
          value: {
            address: locationFilter
          },
          name: "address"
        }}
        meta={{}}
        inputLabel="Enter Location"
      />
      <MarketplaceSortBy
        defaultRefinement="offers-prioritized"
        items={[
          {
            value: SORT_BY_ENUM.relevance,
            label: "Relevance"
          },
          {
            value: SORT_BY_ENUM.lowToHigh,
            label: "Lowest price"
          },
          {
            value: SORT_BY_ENUM.highToLow,
            label: "Highest price"
          },
          {
            value: SORT_BY_ENUM.distance,
            label: "Distance"
          }
        ]}
        resetPage={resetPage}
      />
    </div>
  );
};

MarketplaceFilters.propTypes = {
  handleLocationUpdate: PropTypes.func.isRequired,
  locationFilter: PropTypes.string.isRequired,
  resetPage: PropTypes.func.isRequired
};

MarketplaceSortBy.propTypes = {
  defaultRefinement: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  resetPage: PropTypes.func.isRequired
};

function connectSortBy(Component) {
  const SortBy = props => {
    const data = useSortBy(props);

    return <Component {...props} {...data} />;
  };

  return SortBy;
}
