import React from "react";
import GoogleMapReact from "google-map-react";
import { useHits } from "react-instantsearch";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
  marker: {
    position: "relative",
    background: "#fff",
    border: "1px solid #808080",
    textAlign: "center",
    fontSize: "16px",
    display: "table",
    padding: "10px",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 5px 10px",
    "&::before, &::after": {
      content: "''", // <--- MUST wrap a single quate with a double quate, otherwise the pseudo selectors do NOT show.
      top: "100%",
      left: "50%",
      border: "solid transparent",
      height: 0,
      width: 0,
      position: "absolute",
      pointerEvents: "none"
    },
    "&::before": {
      borderColor: "rgba(128, 128, 128, 0)",
      borderTopColor: "#808080",
      borderWidth: "11px",
      marginLeft: "-11px"
    },
    "&::after": {
      borderColor: "rgba(255, 255, 255, 0)",
      borderTopColor: "#fff",
      borderWidth: "10px",
      marginLeft: "-10px"
    }
  },
  markerActive: {
    background: "#009cec",
    border: "1px solid #009cec",
    color: "#fff",
    fontWeight: "800",
    zIndex: 99999999,
    "&::before": {
      borderTopColor: "#009cec"
    },
    "&::after": {
      borderTopColor: "#009cec"
    }
  }
}));

const rad = function(x) {
  return (x * Math.PI) / 180;
};

export const getDistance = function(p1, p2) {
  var R = 3959; // Earth’s mean radius in miles
  var dLat = rad(p2.lat - p1.lat);
  var dLong = rad(p2.lng - p1.lng);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) *
      Math.cos(rad(p2.lat)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d; // returns the distance in miles
};

const Marker = ({ children, active, onClick }) => {
  const classes = useStyles();
  return (
    <div
      onClick={onClick}
      className={classNames(classes.marker, {
        [classes.markerActive]: active
      })}
    >
      <div>{children}</div>
    </div>
  );
};

export const MarketplaceMap = connectHits(
  ({
    radius,
    lat,
    lng,
    selected,
    hits,
    handleRadiusUpdate,
    handleLocationUpdate,
    handleSelectedUpdate
  }) => {
    return (
      <GoogleMapReact
        options={{
          zoomControlOptions: {
            position: window.google.maps.ControlPosition.LEFT_BOTTOM
          }
        }}
        center={[lat, lng]}
        zoom={10}
        onChange={({ center, bounds }) => {
          if (center.lat !== lat || center.lng !== lng) {
            handleLocationUpdate({
              lat: center.lat,
              lng: center.lng
            });
          }
          const newRadius = Math.round(
            Math.max(
              getDistance(bounds.ne, bounds.nw),
              getDistance(bounds.se, bounds.ne)
            ) / 2
          );
          if (newRadius !== radius) {
            handleRadiusUpdate(newRadius);
          }
        }}
      >
        {hits.map((hit, key) => {
          return (
            <Marker
              key={key}
              lat={hit._geoloc.lat}
              lng={hit._geoloc.lng}
              onClick={() => handleSelectedUpdate(hit.id, key)}
              active={hit.id === selected}
            >
              <div>${(hit.dailyPriceInCents / 100).toFixed(0)}</div>
            </Marker>
          );
        })}
      </GoogleMapReact>
    );
  }
);

MarketplaceMap.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  radius: PropTypes.number,
  handleRadiusUpdate: PropTypes.func.isRequired,
  handleLocationUpdate: PropTypes.func.isRequired,
  selected: PropTypes.string,
  handleSelectedUpdate: PropTypes.func
};

Marker.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  onClick: PropTypes.func,
  active: PropTypes.bool
};

function connectHits(Component) {
  const Hits = props => {
    const data = useHits(props);

    return <Component {...props} {...data} />;
  };

  return Hits;
}
