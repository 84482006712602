import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext
} from "react";
import { useQuery } from "@apollo/client";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import ClearIcon from "@mui/icons-material/Clear";
import makeStyles from "@mui/styles/makeStyles";
import { connect } from "react-redux";
import classNames from "classnames";
import get from "lodash/get";
import upperFirst from "lodash/upperFirst";
import find from "lodash/find";
import jwtDecode from "jwt-decode";

import { sb } from "SendBird/SendBirdUtil";
import { InboxChatMessage } from "./InboxChatMessage";
import {
  asyncGetChannelMessages,
  asyncSendMessageToChannel,
  updateChannelMessageInput
} from "Redux/SendBird/ActionCreators";
import { parseSendbirdMessageData } from "Fleet/Dashboard/DashboardViews/Inbox/helpers";
import { LetterAvatar } from "Components/Avatar/LetterAvatar";
import { useChannelRentalInfo } from "./InboxUtils";
import { inboxRentalStatusEnum } from "Enums/InboxRentalStatusEnum";
import InfoPopover from "./inboxChat/InfoPopover";
import RentalDetails from "./inboxChat/RentalDetails";
import Skeleton from "@mui/material/Skeleton";
import { DriverProfileContext } from "Components/Utils/DriverProfileProvider";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { INBOX_TEMPLATES } from "Queries/InboxTemplate/InboxTemplateQueries";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import optimizeURL from "Driver/utils/cloudImage";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "inherit",
    maxHeight: "inherit"
  },
  header: {
    alignItems: "center",
    display: "flex",
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    flexBasis: "initial",
    [theme.breakpoints.down("md")]: {
      position: "fixed",
      background: theme.palette.background.paper,
      width: "100%"
    }
  },
  flexGrow: {
    flexGrow: "1"
  },
  avatar: {
    marginRight: theme.spacing(2),
    cursor: "pointer"
  },
  avatarInquiry: {
    marginRight: theme.spacing(2)
  },
  chatContainer: {
    padding: theme.spacing(3),
    overflowY: "scroll",
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(8)
    }
  },
  headerSpace: {
    marginTop: 73
  },
  rentalInfoContainer: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  messageWrapper: {
    marginTop: theme.spacing(4)
  },
  inputContainer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down("md")]: {
      position: "fixed",
      background: theme.palette.background.paper,
      bottom: "0",
      width: "100%"
    }
  },
  input: {
    padding: theme.spacing(2),
    resize: "none"
  },
  button: {
    borderRadius: "0",
    borderLeft: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`
  },
  bottom: {
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(8)
    }
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1
  },
  closeIcon: {
    marginRight: theme.spacing(4),
    "&:hover": {
      cursor: "pointer"
    }
  },
  headerContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between"
  },
  statusChip: {
    display: "inline-flex",
    padding: "6px 12px 5px",
    borderRadius: "100px",
    fontWeight: 400,
    color: "#FAFAFA",
    cursor: "default"
  },
  statusChipContainer: {
    display: "flex",
    padding: "8px",
    borderLeft: "1px solid #E5E5E5",
    justifyContent: "center"
  },
  headerInfo: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  loadingHeader: {
    display: "flex",
    margin: "10px 20px"
  },
  loadingLineItems: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "20px"
  },
  userDataUnavailableText: {
    padding: 8
  }
}));

export const InboxChat = connect(
  state => {
    const selectedChannel = get(state, "fleet.sendbird.selectedChannel", null);
    return {
      selectedChannel: selectedChannel,
      messages: selectedChannel
        ? get(state, `fleet.sendbird.messages[${selectedChannel.url}]`)
        : [],
      user: get(state, "fleet.sendbird.user"),
      messagePreview: selectedChannel
        ? get(
            state,
            `fleet.sendbird.channelMessageInputs[${selectedChannel.url}].messagePreview`,
            ""
          )
        : ""
    };
  },
  {
    asyncGetChannelMessages,
    asyncSendMessageToChannel,
    updateChannelMessageInput
  }
)(
  ({
    selectedChannel,
    messages,
    messagePreview,
    user,
    asyncGetChannelMessages,
    asyncSendMessageToChannel,
    updateChannelMessageInput,
    onCloseChat,
    mobile
  }) => {
    const classes = useStyles();
    const scrollRef = useRef();
    const driver = find(
      get(selectedChannel, "members", []),
      member => member.userId !== user.userId
    );
    const rentalInfo = useChannelRentalInfo(driver?.userId);
    const [rentalAnchorEl, setRentalAnchorEl] = useState(null);
    const [inquiryPopoverAnchorEl, setInquiryPopoverAlchorEl] = useState(null);
    const rentalPopoverAnchor = useRef(null);
    const inquiryPopoverRef = useRef(null);

    const filterOptions = createFilterOptions({
      matchFrom: "start"
    });
    const { currentRooftopToken, currentOrganizationClient } = useContext(
      ClientFactoryContext
    );

    const { data, loading } = useQuery(INBOX_TEMPLATES, {
      variables: { id: jwtDecode(currentRooftopToken).id },
      client: currentOrganizationClient
    });

    const inboxTemplateOptionMap = data?.rooftop?.inboxTemplates?.reduce(
      (acc, cur) => {
        acc[`/${cur.title}`] = cur.message;
        return acc;
      },
      {}
    );

    const handleInquiryPopoverOpen = event => {
      setInquiryPopoverAlchorEl(event.currentTarget);
    };

    const handleInquiryPopoverClose = () => {
      setInquiryPopoverAlchorEl(false);
    };

    const handleRentalPopoverOpen = event => {
      setRentalAnchorEl(event.currentTarget);
    };

    const handleRentalPopoverClose = () => {
      setTimeout(() => setRentalAnchorEl(false), 2000);
    };

    const handleOnChange = (e, newValue) => {
      const value =
        newValue?.value ?? inboxTemplateOptionMap[newValue] ?? newValue ?? "";
      updateChannelMessageInput(selectedChannel, value ?? "");
    };

    const sendMessage = e => {
      e.preventDefault();
      if (selectedChannel && Boolean(messagePreview.trim())) {
        asyncSendMessageToChannel(selectedChannel, messagePreview.trim());
        updateChannelMessageInput(selectedChannel, "");
        if (scrollRef && scrollRef.current) scrollRef.current.scrollIntoView();
      }
    };

    const onKeyPress = e => {
      if (e.key === "Enter") {
        sendMessage(e);
      }
    };

    useEffect(() => {
      if (selectedChannel) sb.markChannelAsRead(selectedChannel);
    }, [messages, selectedChannel]);

    const messagesLoaded = !!messages?.messages;
    const messagesEmpty = !messages?.messages?.length;
    useEffect(() => {
      if (
        selectedChannel &&
        (!messagesLoaded || messagesEmpty) &&
        selectedChannel.messageListQuery?.hasMore
      ) {
        asyncGetChannelMessages(selectedChannel);
      }
    }, [
      selectedChannel,
      asyncGetChannelMessages,
      messagesLoaded,
      messagesEmpty
    ]);

    const observer = useRef();
    const lastElementRef = useCallback(
      node => {
        if (messages.loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
          const hasMore = selectedChannel.messageListQuery.hasMore;
          if (entries[0].isIntersecting && hasMore) {
            asyncGetChannelMessages(selectedChannel);
          }
        });
        if (node) observer.current.observe(node);
      },
      [messages, selectedChannel, asyncGetChannelMessages]
    );

    const { onDrawerOpen } = useContext(DriverProfileContext);
    const driverId = driver?.userId;

    const openDriverProfile = () => onDrawerOpen(driverId);

    const loadComplete =
      rentalInfo.status !== "LOADING" && rentalInfo.status !== "INQUIRY";

    const isLoading =
      rentalInfo.status === "LOADING" && rentalInfo.status !== "INQUIRY";

    const isInquiry = rentalInfo.status === "INQUIRY";

    return (
      <Grid container direction="column" className={classes.root} wrap="nowrap">
        {selectedChannel ? (
          <>
            {driver && (
              <Grid item xs={12} className={classes.header}>
                {mobile && (
                  <ClearIcon
                    onClick={onCloseChat}
                    className={classes.closeIcon}
                  />
                )}
                <Avatar
                  ref={inquiryPopoverRef}
                  src={optimizeURL(driver?.profileUrl, "web.inbox.user.avatar")}
                  alt="Driver Avatar"
                  onMouseEnter={handleInquiryPopoverOpen}
                  onMouseLeave={handleInquiryPopoverClose}
                  className={
                    loadComplete ? classes.avatar : classes.avatarInquiry
                  }
                  onClick={loadComplete ? openDriverProfile : null}
                >
                  <LetterAvatar
                    firstName={get(driver, "nickname", "")}
                    onClick={loadComplete ? openDriverProfile : null}
                  />
                </Avatar>
                {isInquiry && (
                  <InfoPopover
                    id="avatar-popover"
                    onClose={handleInquiryPopoverClose}
                    onOpen={handleInquiryPopoverOpen}
                    type="userDataUnavailable"
                    anchorEl={inquiryPopoverAnchorEl}
                    popoverAnchor={inquiryPopoverRef}
                    PopoverProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      }
                    }}
                  >
                    <Typography className={classes.userDataUnavailableText}>
                      Driver Profile Unavailable
                    </Typography>
                  </InfoPopover>
                )}
                <div className={classes.headerInfo}>
                  <div>
                    <Typography
                      component="div"
                      variant="subtitle1"
                      style={{ fontWeight: 600 }}
                    >
                      {get(driver, "nickname", "Name Unavailable")}
                    </Typography>
                    {!mobile && (
                      <div className={classes.rentalInfo}>
                        <Typography
                          component="div"
                          variant="subtitle2"
                          style={{ fontWeight: 300 }}
                        >
                          {rentalInfo.carInfo &&
                            `${rentalInfo.period} • ${rentalInfo.carInfo}`}
                        </Typography>
                      </div>
                    )}
                  </div>
                  {!mobile && (
                    <>
                      <div className={classes.statusChipContainer}>
                        <div
                          aria-owns={
                            rentalAnchorEl ? "rental-popover" : undefined
                          }
                          aria-haspopup="true"
                          onMouseEnter={handleRentalPopoverOpen}
                          onMouseLeave={handleRentalPopoverClose}
                          ref={rentalPopoverAnchor}
                          className={classes.statusChip}
                          style={{
                            backgroundColor:
                              inboxRentalStatusEnum[rentalInfo.status].color
                          }}
                        >
                          {rentalInfo.status &&
                            inboxRentalStatusEnum[rentalInfo.status].title}
                        </div>
                      </div>
                      {loadComplete && (
                        <InfoPopover
                          id="rental-popover"
                          type="rentalInfo"
                          onClose={handleRentalPopoverClose}
                          onOpen={handleRentalPopoverOpen}
                          anchorEl={rentalAnchorEl}
                          rentalInfo={rentalInfo}
                          popoverAnchor={rentalPopoverAnchor}
                        >
                          <RentalDetails rentalInfo={rentalInfo} />
                        </InfoPopover>
                      )}
                    </>
                  )}
                </div>
              </Grid>
            )}
            {mobile && (
              <>
                <div className={classes.headerSpace} />
                {loadComplete && (
                  <Grid item xs={12} className={classes.rentalInfoContainer}>
                    <RentalDetails rentalInfo={rentalInfo} />
                  </Grid>
                )}
                {isLoading && (
                  <Grid item xs={12} className={classes.rentalInfoContainer}>
                    <div className={classes.loadingHeader}>
                      <Skeleton variant="circular" width={35} height={35} />
                      <div className={classes.loadingLineItems}>
                        <Skeleton variant="text" width={150} />
                        <Skeleton variant="text" width={150} />
                        <Skeleton variant="text" width={150} />
                      </div>
                    </div>
                  </Grid>
                )}
              </>
            )}
            <Grid
              container
              className={classNames(classes.flexGrow, classes.chatContainer)}
              direction="column"
              wrap="nowrap"
            >
              {messages ? (
                <>
                  <div ref={scrollRef} className={classes.bottom} />
                  {!messages.loading &&
                    messages.messages.map((message, key) => {
                      const isDriver =
                        message?._sender?.userId === driver?.userId;
                      const {
                        firstName,
                        lastName,
                        profilePhoto
                      } = parseSendbirdMessageData(message);
                      return (
                        <div
                          key={key}
                          {...(key + 1 === messages.messages.length
                            ? { ref: lastElementRef }
                            : {})}
                        >
                          <InboxChatMessage
                            isDriver={isDriver}
                            name={
                              isDriver
                                ? get(message, "_sender.nickname")
                                : `${upperFirst(firstName)} ${upperFirst(
                                    lastName
                                  )}`
                            }
                            profilePhoto={optimizeURL(
                              isDriver
                                ? get(message, "_sender.profileUrl")
                                : profilePhoto,
                              "web.inbox.user.avatar"
                            )}
                            text={get(message, "message")}
                            className={classes.messageWrapper}
                            timestamp={get(message, "createdAt")}
                          />
                        </div>
                      );
                    })}
                </>
              ) : (
                <span>Error</span>
              )}
            </Grid>
          </>
        ) : (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.flexGrow}
          >
            <Typography component="span" variant="h4" color="textSecondary">
              Select a Conversation
            </Typography>
          </Grid>
        )}
        {selectedChannel && (
          <Grid
            container
            onSubmit={sendMessage}
            className={classes.inputContainer}
          >
            <div className={classes.flexContainer}>
              <Autocomplete
                freeSolo
                value={""}
                filterOptions={filterOptions}
                onChange={handleOnChange}
                getOptionLabel={option => option?.label ?? ""}
                onKeyPress={onKeyPress}
                autoHighlight
                open={messagePreview?.startsWith("/")}
                inputValue={messagePreview ?? ""}
                onInputChange={(e, newInputValue) => {
                  updateChannelMessageInput(
                    selectedChannel,
                    newInputValue ?? ""
                  );
                }}
                options={data?.rooftop?.inboxTemplates.map(template => ({
                  label: `/${template.title}`,
                  value: template.message
                }))}
                disabled={loading}
                className={classNames(classes.flexGrow, classes.input)}
                renderInput={params => (
                  <InputBase
                    {...params}
                    ref={params.InputProps.ref}
                    style={{ width: "100%" }}
                    placeholder={
                      loading
                        ? "Loading..."
                        : "Type a message or bring up your quick reply shortcuts by typing /"
                    }
                    multiline
                    rows={4}
                    rowsMax={4}
                  />
                )}
              />
              <Button
                className={classes.button}
                color="primary"
                onClick={sendMessage}
              >
                Send
              </Button>
            </div>
          </Grid>
        )}
      </Grid>
    );
  }
);
