import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import { useQuery } from "@apollo/client";
import moment from "moment";

import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { EMPLOYMENT_HISTORY } from "Queries/Rentals/RentalQueries";

export const EmploymentHistory = ({ classes, userId }) => {
  const { currentRooftopClient } = useContext(ClientFactoryContext);

  const { data: employmentHistory } = useQuery(EMPLOYMENT_HISTORY, {
    variables: { userId },
    client: currentRooftopClient,
    fetchPolicy: "network-only",
    skip: !userId
  });

  const items = employmentHistory?.fetchArgyleEmployementHistory || [];

  if (!items.length) {
    return <></>;
  }

  return (
    <Grid container direction="row" className={classes.rentalContainer}>
      <div className={classes.iconContainer}>
        <ManageHistoryIcon className={classes.historyIcon} />
      </div>
      <Grid container direction="column" className={classes.rentalInfo}>
        <>
          <Typography variant="subtitle1" className={classes.fontStyle}>
            Employment History
          </Typography>

          {items.map(history => (
            <Grid
              container
              direction="column"
              className={classes.rentalContainer}
              key={history.id}
            >
              <Typography variant="subtitle1" className={classes.fontStyle}>
                {history.employer}
              </Typography>

              <Typography variant="body2">
                Trip count: {history.gigsCount}
              </Typography>

              <Typography variant="body2">
                Most recent gig:{" "}
                {history.mostRecentGigDate
                  ? moment(history.mostRecentGigDate).format("MMM D, YYYY")
                  : "N/A"}
              </Typography>

              <Typography variant="body2">
                Driving since:{" "}
                {moment(history.originalHireDate).format("MMM D, YYYY")}
              </Typography>
            </Grid>
          ))}
        </>
      </Grid>
    </Grid>
  );
};
