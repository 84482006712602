import queryString from "query-string";

const GA_TAG_ID = process.env.REACT_APP_GA4_TAG_ID;

export const getSessionData = async () => {
  if (typeof window.gtag !== "undefined") {
    const affiliateSource = window.localStorage.getItem("affiliate_source");
    const affiliateSourceId = window.localStorage.getItem(
      "affiliate_source_id"
    );
    const affiliateReferrer = window.localStorage.getItem("affiliate_referrer");
    const affiliateUtm = window.localStorage.getItem("affiliate_utm");

    const gtagGetPromise = new Promise(resolve => {
      window.gtag("get", GA_TAG_ID, "session_id", resolve);
    });

    const sessionId = await gtagGetPromise;

    const sessionData = {
      ga_session_id: sessionId,
      ...(affiliateSource && { affiliateSource }),
      ...(affiliateSourceId && { affiliateSourceId }),
      ...(affiliateReferrer && { affiliateReferrer }),
      ...(affiliateUtm && { affiliateUtm })
    };

    return sessionData;
  }
  return {};
};

export const updateAffiliateData = history => {
  const search = queryString.parse(history?.location?.search);

  // Clear affiliate localStorage to avoid sending stale data when it has changed
  if (
    search?.affiliate_source ||
    search?.affiliate_source_id ||
    search?.affiliate_referrer ||
    search?.utm_source ||
    search?.utm_medium ||
    search?.utm_campaign
  ) {
    window.localStorage.removeItem("affiliate_source");
    window.localStorage.removeItem("affiliate_source_id");
    window.localStorage.removeItem("affiliate_referrer");
    window.localStorage.removeItem("affiliate_utm");
  }

  // Repopulate affiliate localStorage
  if (search?.affiliate_source)
    window.localStorage.setItem("affiliate_source", search?.affiliate_source);
  if (search?.affiliate_source_id)
    window.localStorage.setItem(
      "affiliate_source_id",
      search?.affiliate_source_id
    );
  if (search?.affiliate_referrer)
    window.localStorage.setItem(
      "affiliate_referrer",
      search?.affiliate_referrer
    );

  let utmParams = [];

  if (search?.utm_source) utmParams.push(`utm_source=${search.utm_source}`);
  if (search?.utm_medium) utmParams.push(`utm_medium=${search.utm_medium}`);
  if (search?.utm_campaign)
    utmParams.push(`utm_campaign=${search.utm_campaign}`);

  const utm = utmParams.join("&");

  if (utm) window.localStorage.setItem("affiliate_utm", utm);
};
