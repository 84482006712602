import React, { createContext, useState, useContext, useEffect } from "react";

const OverpricedCarEventTrackingContext = createContext();

export const OverpricedCarEventTrackingProvider = ({ children }) => {
  const [eventData, setEventData] = useState({
    reviewCarPricingClicked: false,
    overpricedCarClicked: false,
    carOverpricedDrawerClicked: false,
    overpricedCarTagClicked: false
  });

  useEffect(() => {
    const storedEventData = localStorage.getItem(
      "overpricedCarEventTrackingData"
    );
    if (storedEventData) {
      setEventData(JSON.parse(storedEventData));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "overpricedCarEventTrackingData",
      JSON.stringify(eventData)
    );
  }, [eventData]);

  const updateEventData = (key, value) => {
    setEventData(prevData => ({ ...prevData, [key]: value }));
  };

  const clearEventData = () => {
    localStorage.removeItem("overpricedCarEventTrackingData");
    setEventData({
      reviewCarPricingClicked: false,
      overpricedCarClicked: false,
      carOverpricedDrawerClicked: false,
      overpricedCarTagClicked: false
    });
  };

  return (
    <OverpricedCarEventTrackingContext.Provider
      value={{
        eventData,
        updateEventData,
        clearEventData
      }}
    >
      {children}
    </OverpricedCarEventTrackingContext.Provider>
  );
};

export const useOverpricedCarEventTracking = () =>
  useContext(OverpricedCarEventTrackingContext);
