import { useContext } from "react";
import { useMutation, useQuery } from "@apollo/client";
import jwtDecode from "jwt-decode";
import { object, string } from "yup";
import PropTypes from "prop-types";
import get from "lodash/get";
import set from "lodash/set";
import has from "lodash/has";

import { validateLocation } from "Components/Forms/Location/LocationAutoComplete";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { ROOFTOP_INFO } from "Queries/Rooftop/RooftopQueries";
import { UPDATE_ORGANIZATION_ROOFTOP } from "Mutations/Organization/OrganizationMutations";

export const RooftopEditFormController = ({
  children,
  onSubmitSuccess,
  onSubmitFailure
}) => {
  const { currentRooftopToken, currentOrganizationClient } = useContext(
    ClientFactoryContext
  );

  const { data, loading } = useQuery(ROOFTOP_INFO, {
    variables: {
      id: jwtDecode(currentRooftopToken).id
    },
    client: currentOrganizationClient
  });

  const [updateRooftop] = useMutation(UPDATE_ORGANIZATION_ROOFTOP, {
    client: currentOrganizationClient
  });

  const initialAddress = get(data, "rooftop.address", {});

  const fullAddress = get(initialAddress, "streetAddress")
    ? `${initialAddress.streetAddress}, ${initialAddress.city}, ${initialAddress.state}, ${initialAddress.country}`
    : "";

  const initialValues = {
    name: get(data, "rooftop.name"),
    note: get(data, "rooftop.note"),
    email: get(data, "rooftop.legacyRooftop.email"),
    address: {
      address: fullAddress,
      lat: initialAddress.lat,
      lng: initialAddress.lng,
      country: initialAddress.country,
      state: initialAddress.state,
      city: initialAddress.city,
      zip: initialAddress.postalCode,
      street: initialAddress.streetAddress,
      selected: true
    },
    defaultSrc: get(data, "rooftop.legacyRooftop.profilePhoto.url"),
    image: get(data, "rooftop.legacyRooftop.profilePhoto.id"),
    payout: "Chase_1234"
  };

  const onSubmit = async values => {
    try {
      await updateRooftop({
        variables: {
          data: {
            id: jwtDecode(currentRooftopToken).id,
            name: get(values, "name"),
            note: get(values, "note"),
            address: {
              geo: {
                lat: get(values, "address.lat"),
                lng: get(values, "address.lng")
              },
              country: get(values, "address.country"),
              streetAddress: get(values, "address.street"),
              city: get(values, "address.city"),
              state: get(values, "address.state"),
              postalCode: get(values, "address.zip")
            },
            profilePhotoId: get(values, "image", "")
          }
        }
      });

      if (onSubmitSuccess) onSubmitSuccess();
    } catch (e) {
      console.error(e);
      if (onSubmitFailure) onSubmitFailure();
    }
  };

  const validate = values => {
    const errors = {};

    const schema = object().shape({
      name: string().required("Required!"),
      note: string().nullable()
    });

    try {
      schema.validateSync(values, { abortEarly: false, stripUnknown: true });
    } catch (e) {
      e.inner.reduce((acc, error) => {
        if (!has(acc, error.path)) set(acc, error.path, error.message);
        return acc;
      }, errors);
    }

    return {
      ...errors,
      ...validateLocation(values, "address")
    };
  };

  return children({
    onSubmit,
    validate,
    initialValues,
    loading
  });
};

RooftopEditFormController.propTypes = {
  children: PropTypes.func.isRequired,
  onSubmitSucess: PropTypes.func,
  onSubmitFailure: PropTypes.func
};
