/* eslint-disable */
import React, { useState, useEffect, useContext, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { compose } from "recompose";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import get from "lodash/get";
import { SnackbarProvider } from "notistack";
import { TosProvider } from "Components/Utils/TosProvider";
import { useHistory } from "react-router";

import { AuthContext } from "Login/Auth";
import ImpactRadius from "Driver/Components/General/ImpactRadius";
import { UserTypeEnum } from "Enums/StateEnums";
import { LoginController } from "Login/LoginController";
import { CURRENT_USER_QUERY } from "Queries/User/UserQueries";
import withScripts from "Components/hocs/withScripts";
import { FleetContainer } from "Fleet/FleetContainer";
import { DriverApp } from "Driver/DriverApp";
import { UserBlockedModal } from "Components/Dialog/UserBlockedModal";
import { CircularProgress } from "Components/Loading/CircularProgress";
import { ThemeFunctionsContext } from "Styles/ThemeProvider";
import { LegacyRedirect } from "Components/Utils/LegacyRedirect";
import { Identifier } from "Analytics/Identifier";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";
import UnderMaintenance from "UnderMaintenance";
import TermsOfServiceDialog from "Components/Dialog/TermsOfServiceDialog";
import { updateAffiliateData } from "Analytics/utils/getSessionData";

const useStyles = makeStyles(() => ({
  loading: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

const Dashboard = ({ currentUserData, userTypeByDatabase, authUserType }) => {
  if (authUserType && authUserType === UserTypeEnum.owner) {
    return <FleetContainer />;
  } else if (authUserType && authUserType === UserTypeEnum.driver) {
    return <DriverApp currentUser={get(currentUserData, "viewer.me", {})} />;
  } else if (userTypeByDatabase === UserTypeEnum.owner) {
    return <FleetContainer />;
  } else if (userTypeByDatabase === UserTypeEnum.dealer) {
    return <FleetContainer />;
  } else {
    return <DriverApp currentUser={get(currentUserData, "viewer.me", {})} />;
  }
};

/*
 * It is necessary to have loadingFromRefetch because for whatever reason the hook doesn't set loading
 * to true when calling refetch.
 *
 * TODO: Do something with the error from useQuery();
 */
export const Root = compose(withScripts)(({ isScriptLoaded }) => {
  useEffect(() => {
    console.log("Release Version: ", process.env.REACT_APP_VERSION);
  }, []);

  const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [loadingFromRefetch, setLoadingFromRefetch] = useState(false);
  const {
    userIsAuthenticated,
    loading: authLoading,
    authUserType
  } = useContext(AuthContext);
  const { setThemeToDriver, setThemeToFleet } = useContext(
    ThemeFunctionsContext
  );
  const { data, loading, error, refetch } = useQuery(CURRENT_USER_QUERY, {
    fetchPolicy: "network-only"
  });
  const networkErrorStatusCode = get(error, "networkError.statusCode");
  const userBlocked = Boolean(networkErrorStatusCode);
  const userBlockedMessage = get(error, "networkError.result.error");
  const userTypeByDatabase = get(data, "viewer.me.type");
  const history = useHistory();

  useEffect(() => {
    (async () => {
      setLoadingFromRefetch(true);
      try {
        await refetch();
        setLoadingFromRefetch(false);
      } catch (e) {
        console.error(e);
        setLoadingFromRefetch(false);
      }
    })();
  }, [userIsAuthenticated]);

  useEffect(() => {
    switch (userTypeByDatabase) {
      case UserTypeEnum.driver:
        setThemeToDriver();
        break;
      case UserTypeEnum.owner:
      default:
        setThemeToFleet();
        break;
    }
  }, [userTypeByDatabase]);

  useEffect(() => {
    updateAffiliateData(history);
  }, [history]);

  const { config, getValue } = useContext(FirebaseContext);
  const customerServicePhoneNumber = useMemo(
    () => getValue("support_chat_phone"),
    [config]
  );

  const underMaintenance = config.getBoolean("under_maintenance");
  if (underMaintenance) {
    return <UnderMaintenance />;
  }

  return userIsAuthenticated === undefined ? (
    <div />
  ) : (
    <>
      <LegacyRedirect />
      <ImpactRadius userIsAuthenticated={userIsAuthenticated} />
      {userIsAuthenticated ? (
        <>
          {loading || !isScriptLoaded || authLoading || loadingFromRefetch ? (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {userBlocked &&
              userBlockedMessage ===
                `Your account has been temporarily deactivated. If you believe this is an error, please contact customer support at ${customerServicePhoneNumber}` ? (
                <UserBlockedModal show={true} />
              ) : (
                <>
                  <Identifier />
                  <TosProvider>
                    <SnackbarProvider
                      maxSnack={1}
                      dense
                      preventDuplicate
                      anchorOrigin={
                        matches
                          ? { horizontal: "center", vertical: "bottom" }
                          : { horizontal: "left", vertical: "top" }
                      }
                      autoHideDuration={2500}
                    >
                      <TermsOfServiceDialog />
                      <Dashboard
                        currentUserData={data}
                        userTypeByDatabase={userTypeByDatabase}
                        authUserType={authUserType}
                      />
                    </SnackbarProvider>
                  </TosProvider>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <LoginController />
      )}
    </>
  );
});
