import React, { useEffect, useState, Fragment } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { default as MuiTable } from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import TablePagination from "@mui/material/TablePagination";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import PropTypes from "prop-types";
import { joyrideTutorialIds } from "Components/Joyride/JoyrideUtils";
import { Alert, Box } from "@mui/material";
import { useContext } from "react";
import { OverpricedCarsContext } from "Components/Utils/OverpricedCarsProvider";
import { useOverpricedCarEventTracking } from "Components/Utils/OverpricedCarEventTrackingProvider";

const useStyles = makeStyles(theme => ({
  noDataLabelWrapper: {
    padding: `${theme.spacing(6)} 0`
  },
  tableHeadCell: {
    zIndex: 100,
    fontWeight: theme.typography.fontWeightBold,
    background: props => (props.whiteHeader ? theme.palette.common.white : null)
  },
  tableHeadCellTooltip: {
    cursor: "pointer"
  },
  tableWrapper: {
    maxHeight: props =>
      props.tableGrows ? "none" : props.extended ? "590px" : "510px",
    minHeight: props =>
      props.tableShrinks ? "auto" : props.extended ? "590px" : "510px",
    overflow: "auto",
    position: "relative"
  },
  noTableWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  tablePagination: {
    background: "#FFF",
    bottom: "0",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    left: "0",
    position: props => (props.stickyPagination ? "absolute" : "sticky"),
    width: "100%"
  },
  tableRowPointer: {
    cursor: "pointer"
  },
  tableRowHover: {
    "&:hover": {
      boxShadow: theme.shadows[4]
    }
  },
  tableRowGrey: {
    "&.MuiTableRow-root": {
      backgroundColor: "#F4F5F5"
    }
  },
  success: {
    background: theme.palette.success
  },
  warning: {
    background: theme.palette.warning
  },
  greyed: {
    color: theme.palette.grey[300]
  },
  mobileChevronColumnHeader: {
    paddingRight: "32px"
  },
  mobileChevronTableCell: {
    paddingRight: "32px",
    position: "relative"
  },
  mobileChevron: {
    color: "rgba(0, 0, 0, .54)",
    position: "absolute",
    right: "4px",
    top: "50%",
    transform: "translateY(-50%)"
  },
  overPriceAlert: {
    border: "none",
    padding: 0,
    marginLeft: theme.spacing(1),
    "& .MuiAlert-icon": {
      marginRight: 0,
      padding: 0,
      fontSize: "16px"
    }
  }
}));
export const Table = ({
  columns,
  data,
  error,
  errorComponent,
  noDataLabel,
  noDataComponent,
  noDataCellLabel,
  disableHover,
  disableRowPointer,
  loading,
  loadingComponent,
  onRowClick,
  size,
  extended,
  tableGrows,
  tableShrinks,
  paginate,
  mobileChevron,
  whiteHeader = true,
  stickyPagination,
  rowsPerPageOptions = [50, 100]
}) => {
  const classes = useStyles({
    extended,
    tableGrows,
    tableShrinks,
    stickyPagination,
    whiteHeader
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const { isCarOverpriced } = useContext(OverpricedCarsContext);
  const { updateEventData } = useOverpricedCarEventTracking();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const dataAmt = data?.length;
  useEffect(() => {
    if (paginate) {
      setPage(0);
    }
  }, [paginate, dataAmt]);

  const renderLoadingComponent = () => {
    return loadingComponent ? (
      <Grid
        container
        justifyContent="center"
        className={classes.noDataLabelWrapper}
      >
        {loadingComponent()}
      </Grid>
    ) : (
      <Typography component="span" variant="h5" className={classes.loadingText}>
        Loading...
      </Typography>
    );
  };
  const noData = data?.length === 0 && !loading && !error;
  const isLoading = loading && !error;
  const hasData = data && data.length && !loading && !error;
  const dataToDisplay = paginate
    ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : data;

  const handleRowClicked = d => {
    onRowClick(d);
    let overpriced = isCarOverpriced(d.id);
    overpriced && updateEventData("overpricedCarTagClicked", true);
  };

  return (
    <div
      className={classNames(classes.tableWrapper, {
        [classes.noTableWrapper]: loading || data.length === 0
      })}
      id={joyrideTutorialIds.tableWrapper}
    >
      {error ? errorComponent() : null}
      {noData ? (
        <Grid
          container
          justifyContent="center"
          className={classes.noDataLabelWrapper}
        >
          {noDataComponent && noDataComponent}
          {noDataLabel && (
            <Typography component="span" variant="h5">
              {noDataLabel}
            </Typography>
          )}
        </Grid>
      ) : null}
      {isLoading ? <Fragment>{renderLoadingComponent()}</Fragment> : null}
      {hasData ? (
        <Fragment>
          <MuiTable stickyHeader size={size ? size : "medium"}>
            <TableHead>
              <TableRow>
                {columns.map(
                  ({ title, tooltip, align, hidden, width }, key) => {
                    if (hidden) return null;
                    return tooltip ? (
                      <Tooltip key={key} title={tooltip} enterTouchDelay={0}>
                        <TableCell
                          align={align}
                          style={{ width: width }}
                          className={classNames(classes.tableHeadCell, {
                            [classes.tableHeadCellTooltip]: !disableRowPointer
                          })}
                        >
                          {title}
                        </TableCell>
                      </Tooltip>
                    ) : (
                      <TableCell
                        key={key}
                        style={{ width: width }}
                        className={classNames(classes.tableHeadCell, {
                          [classes.mobileChevronColumnHeader]:
                            mobileChevron && key === columns.length - 1
                        })}
                        align={align}
                      >
                        {title}
                      </TableCell>
                    );
                  }
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataToDisplay.map((d, key) => {
                return (
                  <TableRow
                    key={key}
                    style={d.style || {}}
                    className={classNames({
                      [classes.tableRowPointer]: !disableRowPointer,
                      [d.className]: d.className,
                      [classes.tableRowHover]: !disableHover,
                      [classes.tableRowGrey]: d.tableRowGrey
                    })}
                    onClick={onRowClick ? () => handleRowClicked(d) : null}
                  >
                    {columns.map((column, key) => {
                      if (column.hidden) return null;
                      const isPriceCell =
                        column.field === "dailyPriceInDollars";
                      const priceIsOverpriced =
                        isPriceCell && isCarOverpriced(d.id);

                      return d[column.field]?.tooltip ? (
                        <Tooltip
                          key={key}
                          title={d[column.field]?.tooltip}
                          enterTouchDelay={0}
                        >
                          <TableCell
                            align={column.align}
                            className={classNames({
                              [classes.success]: d.success,
                              [classes.warning]: d.warning,
                              [classes.error]: d.error,
                              [classes.greyed]: d.greyed,
                              [classes.mobileChevronTableCell]:
                                mobileChevron && key === columns.length - 1
                            })}
                          >
                            {d[column.field]
                              ? typeof d[column.field] === "object"
                                ? React.isValidElement(d[column.field])
                                  ? d[column.field]
                                  : d[column.field].value
                                : d[column.field]
                              : noDataCellLabel
                              ? noDataCellLabel
                              : "No Data"}
                            {mobileChevron && key === columns.length - 1 ? (
                              <ChevronRightIcon
                                className={classes.mobileChevron}
                              />
                            ) : null}
                          </TableCell>
                        </Tooltip>
                      ) : (
                        <TableCell
                          key={key}
                          align={column.align}
                          className={classNames({
                            [classes.success]: d.success,
                            [classes.warning]: d.warning,
                            [classes.error]: d.error,
                            [classes.greyed]: d.greyed,
                            [classes.mobileChevronTableCell]:
                              mobileChevron && key === columns.length - 1
                          })}
                        >
                          {d[column.field] ? (
                            typeof d[column.field] === "object" ? (
                              React.isValidElement(d[column.field]) ? (
                                d[column.field]
                              ) : (
                                d[column.field].value
                              )
                            ) : priceIsOverpriced ? (
                              <Box display="flex" alignItems="center">
                                <span>{d[column.field]}</span>
                                <Alert
                                  severity="warning"
                                  variant="outlined"
                                  className={classes.overPriceAlert}
                                />
                              </Box>
                            ) : (
                              d[column.field]
                            )
                          ) : (
                            noDataCellLabel || "No Data"
                          )}

                          {mobileChevron && key === columns.length - 1 ? (
                            <ChevronRightIcon
                              className={classes.mobileChevron}
                            />
                          ) : null}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </MuiTable>
        </Fragment>
      ) : null}
      {hasData && paginate && data?.length && !loading ? (
        <TablePagination
          className={classes.tablePagination}
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : null}
    </div>
  );
};
Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node]),
      field: PropTypes.string.isRequired
    })
  ).isRequired,
  data: PropTypes.array,
  noDataLabel: PropTypes.string,
  noDataComponent: PropTypes.node,
  noDataCellLabel: PropTypes.string,
  disableHover: PropTypes.bool,
  loading: PropTypes.bool,
  onRowClick: PropTypes.func,
  size: PropTypes.oneOf(["small", "medium"]),
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  stickyPagination: PropTypes.bool
};
