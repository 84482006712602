import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo
} from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import { print } from "graphql";
import axios from "axios";
import get from "lodash/get";
import qs from "query-string";
import { useHistory, useLocation } from "react-router";

import { client, resetAPIClients } from "Components/Utils/ApolloProvider";
import { ClientFactoryContext } from "Components/Utils/ClientProvider";
import { FirebaseContext } from "Components/Utils/FirebaseProvider";
import { authController } from "./AuthController";
import {
  SIGN_UP_USER,
  RESET_PASSWORD,
  CONFIRM_RESET_PASSWORD,
  RECAPTCHA
} from "Mutations/Auth/AuthMutations";
import { CURRENT_USER_QUERY, USER_TYPE } from "Queries/User/UserQueries";
import { SWITCH_ACCOUNT } from "Mutations/User/UserMutations";
import { analytics } from "Analytics/index";
import { UserTypeEnum } from "Enums/StateEnums";
import { getDecodedItemFromQueryParams } from "Driver/utils/helpers";
import { resetApp } from "Redux/ActionCreators";
import { CURRENT_TOS_VERSION } from "Queries/TOS/TOSQueries";
import { AnalyticsContext } from "Analytics/AnalyticsContext";
import config from "Config/config";
import { AnalyticsEvents } from "Analytics/AnalyticsEvents";
import { trackLeadReferrer } from "Analytics/index";
import { RouteEnum } from "Enums/RouteEnum";
import { updateUserComunicationsConfig } from "API/restAPI";
import { getSessionData } from "Analytics/utils/getSessionData";

export const AuthContext = createContext({});

export const AuthProvider = compose(connect(null, { resetApp }))(
  ({ children, resetApp }) => {
    const history = useHistory();

    const { pathname, search } = useLocation();

    const { handleLoginMetrics } = useContext(AnalyticsContext);
    const { resetClients } = useContext(ClientFactoryContext);
    const [confirmResetPassword] = useMutation(CONFIRM_RESET_PASSWORD);
    const [resetPassword] = useMutation(RESET_PASSWORD);

    const [loading, setLoading] = useState(false);
    const [socialLoginSubmitting, setSocialLoginSubmitting] = useState(false);
    const [switchAccountLoading, setSwitchAccountLoading] = useState(false);
    const [userIsAuthenticated, setUserIsAuthenticated] = useState(undefined);
    const [authUserType, setUserType] = useState(undefined);

    const currentUser = useQuery(CURRENT_USER_QUERY, {
      fetchPolicy: "cache-first"
    });

    const { config: remoteConfiguration, getValue } = useContext(
      FirebaseContext
    );
    const customerServicePhoneNumber = useMemo(
      () => getValue("support_chat_phone"),
      [remoteConfiguration]
    );

    useEffect(() => {
      (async () => {
        setLoading(true);
        await authController.subscribe;
        if (authController.isIdTokenValid()) {
          switchAccountOnLogin().then(() => {
            const incomingPath = window.localStorage.getItem("incomingPath");
            setUserIsAuthenticated(true);
            if (incomingPath) {
              history.push(incomingPath);
              window.localStorage.removeItem("incomingPath");
            }
            setLoading(false);
          });
        } else {
          try {
            await authController.refreshToken();
            if (authController.isIdTokenValid()) {
              const incomingPath = window.localStorage.getItem("incomingPath");
              setUserIsAuthenticated(true);
              if (incomingPath) {
                history.push(incomingPath);
                window.localStorage.removeItem("incomingPath");
              }
              setLoading(false);
            } else {
              if (!(pathname.includes("/login") || pathname === "/")) {
                window.localStorage.setItem(
                  "incomingPath",
                  `${pathname}${search}`
                );
              }
              setUserIsAuthenticated(false);
              setLoading(false);
            }
          } catch (e) {
            authController.logout();
            analytics.reset();
            setUserIsAuthenticated(false);
            setLoading(false);
          }
        }
      })();
      // eslint-disable-next-line
    }, []);

    const trackLoginRequest = () => {
      analytics.track(
        AnalyticsEvents.label.auth.loginRequested.legacyEventName,
        {
          category: AnalyticsEvents.category.userInteraction,
          action: AnalyticsEvents.action.formSubmitted,
          label: AnalyticsEvents.label.auth.loginRequested.label,
          property: JSON.stringify({
            provider: "email",
            flow: AnalyticsEvents.flow.login
          }),
          value: "",
          context: ""
        }
      );
    };
    const trackLoginSuccess = () => {
      analytics.track(AnalyticsEvents.label.auth.userLoggedIn.legacyEventName, {
        category: AnalyticsEvents.category.userInteraction,
        action: AnalyticsEvents.action.webConversion,
        label: AnalyticsEvents.label.auth.userLoggedIn.label,
        property: JSON.stringify({
          provider: "email",
          flow: AnalyticsEvents.flow.login
        }),
        value: "",
        context: ""
      });
    };

    const handleLogin = async ({ username, password }, updateUserSettings) => {
      trackLoginRequest();
      await authController.login(
        customerServicePhoneNumber,
        username,
        password
      );
      handleLoginMetrics(true);
      if (updateUserSettings) await updateUserSettings();
      setUserIsAuthenticated(true);
      trackLoginSuccess();

      await switchAccountOnLogin();

      const incomingPath = window.localStorage.getItem("incomingPath");
      if (incomingPath) {
        history.push(incomingPath);
        window.localStorage.removeItem("incomingPath");
      }
    };
    const handleLoginWithAuth0 = async ({ username, password }) => {
      await authController.loginWithAuth0(username, password);
    };

    const handleLoginWithFacebook = userType => {
      setSocialLoginSubmitting(true);
      window.localStorage.setItem("oauth-usertype", userType);
      window.localStorage.setItem("social-login", true);
      authController.loginWithFacebook();
    };

    const handleLoginWithGoogle = userType => {
      setSocialLoginSubmitting(true);
      window.localStorage.setItem("oauth-usertype", userType);
      window.localStorage.setItem("social-login", true);
      authController.loginWithGoogle();
    };

    const handleLoginWithApple = userType => {
      setSocialLoginSubmitting(true);
      window.localStorage.setItem("oauth-usertype", userType);
      window.localStorage.setItem("social-login", true);
      authController.loginWithApple();
    };

    const handleLogout = async () => {
      await resetAPIClients();
      await authController.logout();
      await window.localStorage.removeItem("rooftopToken");
      await window.localStorage.removeItem("organizationToken");
      analytics.reset();
      setUserIsAuthenticated(false);
      resetApp();
      resetClients();
    };

    const trackSignupError = (error, user) => {
      analytics.track(
        AnalyticsEvents.label.auth.userRegistrationFailed.legacyEventName,
        {
          category: AnalyticsEvents.category.deviceNetworking,
          action: AnalyticsEvents.action.requestFailed,
          label: AnalyticsEvents.label.auth.userRegistrationFailed.label,
          property: JSON.stringify({
            driverId: get(user, "data.signUpUser.driver.id"),
            ownerId: get(user, "data.signUpUser.owner.id"),
            inum: get(user, "data.signUpUser.auth0Id"),
            provider: "email",
            flow: AnalyticsEvents.flow.registration,
            error
          }),
          value: null,
          context: null
        }
      );
    };

    const trackSignupSuccess = user => {
      analytics.track(
        AnalyticsEvents.label.auth.userRegisteredSuccessfully.legacyEventName,
        {
          category: AnalyticsEvents.category.deviceNetworking,
          action: AnalyticsEvents.action.requestFailed,
          label: AnalyticsEvents.label.auth.userRegisteredSuccessfully.label,
          property: JSON.stringify({
            driverId: get(user, "data.signUpUser.driver.id"),
            ownerId: get(user, "data.signUpUser.owner.id"),
            inum: get(user, "data.signUpUser.auth0Id"),
            provider: "email",
            flow: AnalyticsEvents.flow.registration
          }),
          value: null,
          context: null
        }
      );
    };

    const handleSignup = async ({
      firstName,
      lastName,
      userType,
      password,
      email,
      phone,
      zip
    }) => {
      const referrer = getDecodedItemFromQueryParams("referrer");
      const impactClickId = qs.parse(window.location.search).clickid;
      const accessToken = await authController.getClientAccessToken();
      const sessionData = await getSessionData();

      const { data: user } = await axios.post(
        config.graphqlUrl,
        {
          query: print(SIGN_UP_USER),
          variables: {
            firstName,
            lastName,
            userType,
            password,
            email,
            phone,
            zip,
            referrer,
            impactClickId
          }
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            "x-google-session-id": sessionData?.ga_session_id,
            ...(sessionData?.affiliateSource && {
              "x-marketing-affiliate-source": sessionData?.affiliateSource
            }),
            ...(sessionData?.affiliateSourceId && {
              "x-marketing-affiliate-source-id": sessionData?.affiliateSourceId
            }),
            ...(sessionData?.affiliateReferrer && {
              "x-marketing-affiliate-referrer": sessionData?.affiliateReferrer
            }),
            ...(sessionData?.affiliateUtm && {
              "x-marketing-affiliate-utm": sessionData?.affiliateUtm
            }),
            "apollographql-client-name": config.clientName,
            "apollographql-client-version": "1.4.0"
          }
        }
      );
      const error = get(user, "errors[0].message");
      if (error) {
        trackSignupError(error, user);
        throw new Error(error);
      }
      const userId = get(user, "data.signUpUser.id");

      const { data } = await client.query({
        query: CURRENT_TOS_VERSION
      });
      const currentTos = data;
      if (!currentTos)
        throw new Error("There was an error. Please contact support.");

      if (userId) window && window.snowplow("setUserId", userId);

      trackSignupSuccess(user);
      analytics.track(AnalyticsEvents.label.auth.tosAccepted, {
        category: AnalyticsEvents.category.userInteraction,
        action: AnalyticsEvents.action.webConversion,
        label: AnalyticsEvents.label.auth.tosAccepted,
        property: JSON.stringify({
          versionAccepted: currentTos.CURRENT_TOS_VERSION
        }),
        value: "",
        context: ""
      });
      await window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute(
          process.env.REACT_APP_RECAPTCHA_CLIENT_KEY,
          {
            action: "submit"
          }
        );

        await axios.post(config.graphqlUrl, {
          query: print(RECAPTCHA),
          variables: {
            data: {
              token,
              userId: user.data.signUpUser.id
            }
          }
        });
      });

      window.snaptr("init", "3fb792a2-09df-454a-aa98-650e9a403cf6", {
        user_email: email
      });
      window.snaptr("track", "SIGN_UP");
    };

    const handleUpdateUserComunicationsConfig = async (
      marketing,
      transactional
    ) => {
      try {
        await updateUserComunicationsConfig(!!marketing, !!transactional);
      } catch (error) {
        console.error("Error updating user subscriptions: ", error);
      }
    };

    const handleSignupAndLogin = async ({
      firstName,
      lastName,
      userType,
      password,
      email,
      phone,
      zip,
      marketing,
      transactional
    }) => {
      await handleSignup({
        firstName,
        lastName,
        userType,
        password,
        email,
        phone,
        zip
      });

      await handleLogin({ username: email, password }, () =>
        handleUpdateUserComunicationsConfig(marketing, transactional)
      );

      trackLeadReferrer();

      handleLoginMetrics(false);
    };

    const handleResetPassword = async ({ email }) => {
      return await resetPassword({ variables: { email: email } });
    };

    const handleConfirmResetPassword = async ({
      password,
      confirmPassword,
      token
    }) => {
      return await confirmResetPassword({
        variables: {
          password: password,
          confirmPassword: confirmPassword,
          token: token
        }
      });
    };

    const switchAccountOnLogin = async () => {
      const { data } = await client.query({
        query: USER_TYPE,
        fetchPolicy: "network-only"
      });
      const userType = get(data, "viewer.me.type", UserTypeEnum.driver);
      const OAuthUsertype = window.localStorage.getItem("oauth-usertype");
      if (
        (OAuthUsertype && OAuthUsertype !== userType) ||
        (authUserType && authUserType !== userType)
      ) {
        window.localStorage.removeItem("oauth-usertype");
        await client.mutate({
          mutation: SWITCH_ACCOUNT
        });
      }
    };

    const switchAccount = async () => {
      setSwitchAccountLoading(true);
      await client.mutate({
        mutation: SWITCH_ACCOUNT
      });
      const currentUserType = get(currentUser, "data.viewer.me.type");
      const newUserType =
        currentUserType === UserTypeEnum.driver
          ? UserTypeEnum.owner
          : UserTypeEnum.driver;
      setUserType(newUserType);
      const newRoute =
        currentUserType === UserTypeEnum.driver
          ? RouteEnum.baseRoute
          : RouteEnum.offers;
      history.replace(newRoute);
      setSwitchAccountLoading(false);
    };

    return (
      <AuthContext.Provider
        value={{
          confirmResetPassword: handleConfirmResetPassword,
          loading,
          login: handleLogin,
          loginWithApple: handleLoginWithApple,
          loginWithAuth0: handleLoginWithAuth0,
          loginWithFacebook: handleLoginWithFacebook,
          loginWithGoogle: handleLoginWithGoogle,
          logout: handleLogout,
          resetPassword: handleResetPassword,
          setUserType,
          signup: handleSignup,
          signupAndLogin: handleSignupAndLogin,
          socialLoginSubmitting,
          switchAccount,
          switchAccountLoading,
          userIsAuthenticated: userIsAuthenticated,
          authUserType
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
);
