import { gql } from "@apollo/client";

export const RooftopFragments = {
  rooftopInfo: gql`
    fragment RooftopInfo on Rooftop {
      id
      legacyId
      name
      note
      email
    }
  `,
  legacyRooftop: gql`
    fragment LegacyRooftop on LegacyRooftop {
      id
      fullName
      profilePhoto {
        id
        url
      }
      email
    }
  `
};
