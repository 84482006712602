import React, { Fragment } from "react";
import { useHits } from "react-instantsearch";
import PropTypes from "prop-types";
import { compose } from "recompose";
import makeStyles from "@mui/styles/makeStyles";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { getDistance } from "./MarketplaceMap";
import { ScrollingContainer } from "./ScrollingContainer";

const useStyles = makeStyles(theme => ({
  avatarContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  dailyPrice: {
    fontWeight: theme.typography.fontWeightMedium,
    color: "#000"
  },
  selected: {
    backgroundColor: "rgba(3, 169, 244, 0.12) !important"
  },
  loadingText: {
    padding: `${theme.spacing(6)} 0`,
    textAlign: "center"
  }
}));

const CarListTable = ({ data, noDataLabel, handleSelectedUpdate }) => {
  const classes = useStyles();

  return (
    <Fragment>
      {data.length === 0 ? (
        <Typography component="h5" variant="h5" className={classes.loadingText}>
          {noDataLabel}
        </Typography>
      ) : (
        <Table>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                key={`${row.id}_${index}`}
                selected={row["selected"] ? true : false}
                classes={{ selected: classes.selected }}
                onClick={() => {
                  handleSelectedUpdate(row["id"]);
                }}
              >
                <TableCell align="center">{row["car"]}</TableCell>
                <TableCell align="left">{row["carPrice"]}</TableCell>
                <TableCell align="right">{row["distance"]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Fragment>
  );
};

export const MarketplaceTable = compose(connectHits)(
  ({ hits, selected, handleSelectedUpdate, location, position }) => {
    const classes = useStyles();
    const mappedData = hits.map(hit => {
      return {
        id: hit.id,
        price: `$${(hit.dailyPriceInCents / 100).toFixed(2)}`,
        carPrice: (
          <div>
            <Typography
              variant="body2"
              component="p"
              className={classes.dailyPrice}
            >
              {`$${(hit.dailyPriceInCents / 100).toFixed(0)}`}
            </Typography>
            <Typography variant="body2" component="p">
              {`${hit.year} ${hit.make} ${hit.model}`}
            </Typography>
          </div>
        ),
        distance: (
          <div>
            <Typography variant="body2" component="p" noWrap>
              {`${getDistance(hit._geoloc, location).toFixed(2)} mi away`}
            </Typography>
            {hit["maxDailyMiles"] && (
              <Typography
                variant="body2"
                component="p"
                noWrap
              >{`${hit.maxDailyMiles} mi per day`}</Typography>
            )}
          </div>
        ),
        car: (
          <div className={classes.avatarContainer}>
            {hit.mainPhoto ? (
              <Avatar alt="Driver" src={hit.mainPhoto} />
            ) : (
              <Avatar>
                <ImageIcon />
              </Avatar>
            )}
          </div>
        ),
        selected: hit.id === selected
      };
    });
    const noDataLabel = "You do not have any marketplace data";

    return (
      <ScrollingContainer position={position}>
        <CarListTable
          data={mappedData}
          noDataLabel={noDataLabel}
          handleSelectedUpdate={handleSelectedUpdate}
        />
      </ScrollingContainer>
    );
  }
);

MarketplaceTable.propTypes = {
  hits: PropTypes.object,
  selected: PropTypes.string,
  handleSelectedUpdate: PropTypes.func,
  location: PropTypes.object,
  position: PropTypes.number
};

function connectHits(Component) {
  const Hits = props => {
    const data = useHits(props);

    return <Component {...props} {...data} />;
  };

  return Hits;
}
